/*------ 15. Timer style  -----*/

.funfact-area.bg-img {
  position: relative;
  @media #{$md-layout} {
    background-position: 15%;
    &:before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      content: "";

      opacity: 0.7;
      background-color: #fff;
    }
  }
  @media #{$xs-layout} {
    background-position: 15%;
    &:before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      content: "";

      opacity: 0.7;
      background-color: #fff;
    }
  }
}

.funfact-content {
  position: relative;
  z-index: 9;
  h2 {
    font-size: 48px;

    margin: 0;

    color: $theme-text;
    @media #{$xs-layout} {
      font-size: 32px;
    }
  }
  h3 {
    color: transparentize($theme-text, 0.1);
  }
  .timer {
    margin: 65px 0 0;

    text-align: center;
    @media #{$lg-layout} {
      margin: 44px 0 0;
    }
    @media #{$xs-layout} {
      margin: 24px 0 0;
    }
    span {
      font-size: 48px;
      font-weight: 400;

      display: inline-block;

      min-width: 75px;
      margin: 0 32px;

      color: $theme-title;
      @media #{$lg-layout} {
        font-size: 40px;

        margin: 0 15px;
      }
      @media #{$xs-layout} {
        font-size: 22px;

        min-width: 55px;
        margin: 0 5px;
      }
      p {
        font-size: 18px;
        font-weight: 500;

        margin: 34px 0 0;

        text-transform: uppercase;

        color: #555;
        @media #{$lg-layout} {
          margin: 24px 0 0;
        }
        @media #{$xs-layout} {
          font-size: 14px;

          margin: 12px 0 0;
        }
      }
    }
  }

  &--style2 {
    h2 {
      font-family: $josefin;
    }
  }
  .funfact-btn {
    margin-top: 43px;
    @media #{$xs-layout} {
      margin-top: 23px;
    }
    a {
      border-radius: $btn-border-radius;
      line-height: 1;

      z-index: 1;

      display: inline-block;

      padding: 16px 56px;

      color: #fff;
      border: 1px solid transparent;
      background-color: $theme-btn;
      box-shadow: $bg-shadow;
      &:hover {
        border: 1px solid $theme-color;
      }
      border-radius: $btn-border-radius;
    }
  }
}
