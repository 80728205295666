/*------ 16. Breadcrumb style  --------*/

.breadcrumb-area {
  margin-top: 25px;
  margin-bottom: 1.5rem;
  ol li {
    display: flex;
  }
  @media #{$xs-layout} {
    padding-top: 12px;
    margin-bottom: 0;
  }
}
