/*-------- 8. Footer style  -----*/

.copyright {
  .footer-logo {
    margin-bottom: 12px;
    img {
      width: 100%;
      max-width: 250px;
    }
    @media #{$xs-layout} {
      margin-top: 0;
      img {
        width: 65%;
      }
    }
    @media #{$md-layout} {
      margin-top: 0;
    }
  }
  p {
    letter-spacing: 0.9px;

    color: transparentize(white, 0.2);
    a {
      color: transparentize(white, 0.2);
      &:hover {
        color: white;
      }
    }
  }
  &.copyright-white {
    p {
      color: #fff;
      a {
        color: #fff;
        &:hover {
          color: $theme-color;
        }
      }
    }
  }
}

.footer-widget {
  .footer-title {
    h3 {
      font-size: 21px;
      font-weight: 500;
      line-height: 1.5;
      letter-spacing: .6px;

      margin: 0 0 16px;

      color: white;
    }
  }
  .footer-list {
    ul {
      li {
        margin: 0 0 11px;
        &:last-child {
          margin: 0 0 0;
        }
        a, span {
          letter-spacing: 0.3px;

          color: transparentize(white, 0.2);
          &:hover {
            color: white;
          }
        }
      }
    }
  }
  .subscribe-style {
    p {
      margin: 0 0 20px;

      color: #7a7a7a;
    }
    .subscribe-form {
      input {
        font-size: 13px;

        padding: 2px 10px 2px 0;

        color: #333;
        border: none;
        border-bottom: 2px solid #ebebeb;
        background: transparent;
      }
      .mc-news {
        display: none;
      }
      .clear {
        button {
          font-size: 14px;
          line-height: 1;

          width: auto;
          height: auto;
          margin: 13px 0 0;
          padding: 0 0 1px;

          text-transform: uppercase;

          color: #313131;
          border: none;
          border-bottom: 2px solid #9b9b9c;
          background: none;
          &:hover {
            color: $theme-color;
            border-bottom: 2px solid $theme-color;
          }
        }
      }
    }
    &.subscribe-style-white {
      p {
        color: #e6e6e6;
      }
      .subscribe-form {
        input {
          color: #a4a4a4;
          border-bottom: 2px solid #ebebeb;
        }
        .clear {
          input,
          button {
            color: #fff;
            border-bottom: 2px solid #b5b5b6;
            &:hover {
              color: $theme-color;
              border-bottom: 2px solid $theme-color;
            }
          }
        }
      }
    }
  }
  @media #{$xx-layout} {
    &.ml-95 {
      margin-left: 20px;
    }
    &.ml-145 {
      margin-left: 50px;
    }
  }
  @media #{$xl-layout} {
    &.ml-95 {
      margin-left: 0;
    }
    &.ml-145 {
      margin-left: 20px;
    }
    &.ml-30 {
      margin-left: 0;
    }
    &.ml-ntv5 {
      margin-left: -50px;
    }
  }
  @media #{$lg-layout} {
    &.ml-95 {
      margin-left: 0;
    }
    &.ml-145 {
      margin-left: 0;
    }
    &.ml-30 {
      margin-left: 0;
    }
    &.ml-ntv5 {
      margin-left: 0;
    }
  }
  @media #{$md-layout} {
    &.ml-95 {
      margin-left: 0;
    }
    &.ml-145 {
      margin-left: 0;
    }
    &.ml-30 {
      margin-left: 0;
    }
    &.ml-ntv5 {
      margin-left: 0;
    }
  }
  @media #{$xs-layout} {
    &.ml-95 {
      margin-left: 0;
    }
    &.ml-145 {
      margin-left: 0;
    }
    &.ml-30 {
      margin-left: 0;
    }
    &.ml-ntv5 {
      margin-left: 0;
    }
  }

  &.footer-widget-white {
    .footer-title {
      h3 {
        color: #fff;
      }
    }
    .footer-list {
      ul {
        li {
          a {
            color: #e6e6e6;
            &:hover {
              color: $theme-color;
            }
          }
        }
      }
    }
  }
}

.footer-widget.ml-30,
.footer-widget.ml-50,
.footer-widget.ml-75 {
  @media #{$md-layout} {
    margin-left: 0;
  }
  @media #{$xs-layout} {
    margin-left: 0;
  }
  @media #{$lg-layout} {
    margin-left: 0;
  }
}

.footer-widget.ml-70 {
  @media #{$md-layout} {
    margin-left: -130px;
  }
  @media #{$xs-layout} {
    margin-left: 0;
  }
  @media #{$sm-layout} {
    margin-left: -90px;
  }
  @media #{$lg-layout} {
    margin-left: 0;
  }
}

.footer-area {
  position: relative;
  background: $theme-header;
  &.ml-10 {
    margin-left: 0;
  }
  @media #{$xl-layout} {
    &.ml-70 {
      margin-left: 15px;
    }
    &.mr-70 {
      margin-right: 15px;
    }
  }
  @media #{$lg-layout} {
    &.ml-70 {
      margin-left: 30px;
    }
    &.mr-70 {
      margin-right: 30px;
    }
  }
  @media #{$md-layout} {
    text-align: center;
    &.ml-70 {
      margin-left: 40px;
    }
    &.mr-70 {
      margin-right: 40px;
    }
  }
  @media #{$xs-layout} {
    text-align: center;
    &.ml-70 {
      margin-left: 15px;
    }
    &.mr-70 {
      margin-right: 15px;
    }
  }
}

.payment-methods-container {
  width: 100%;
  padding: 24px;
  text-align: center;

  border-top-width: .3px;
  border-bottom-width: .3px;
  border-left-width: 0;
  border-right-width: 0;
  border-style: solid;
  border-color: #8a9f64b3;

  img {
    width: 240px;
  }
}
